import {
  fileSubmit,
  getFileList,
  prjbasicinfoPageByCompanyId,
  prjbasicinfoPageByUserId,
  prjthirduserPage, prjthirduserSubmit
} from "@/api/safe/itemList";
import {dateFormat} from "@/util/date";
import {prjblacklistorglist} from "@/api/block/organization";
import * as API from "@/api/personnelHolography";
import {decideLinePage} from "@/api/responsibility/grsafetydecide";
import {getList} from "@/api/safe/projectEAExitLog";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["language", "themeColor", "showStripe"]),
    headBtnOptions() {
      let result = [];
      result.push({
        label: "",
        emit: "head-save",
        type: "button",
        show: () => {
          return this.form.id ? true : false
        },
        btnOptType: "save",
      });
      return result;
    },
    userBtnOptions() {
      let result = [];
      result.push(
        {
          label: '保存',
          show: () => {
            return this.pageType != 'view' && this.APITYPE == 'user'
          },
          handle: () => {
            this.saveBack = false
            this.handleTreeNodeSave()
          },
          btnOptType: "primary",
        }, {
          label: '保存返回',
          show: () => {
            return this.pageType != 'view' && this.APITYPE == 'user'
          },
          handle: () => {
            this.saveBack = true
            this.handleTreeNodeSave()
          },
          btnOptType: "saveBack",
        },
        {
          label: "删除",
          show: (row) => {
            return !this.APITYPE && (row.isUsed == 2 || row.isUsed == 1)
          },
          handle: (row) => {
            this.rowdel(row)
          },
          btnOptType: 'text',
        }, {
          label: "编辑",
          show: (row) => {
            return !this.APITYPE
          },
          handle: (row) => {
            this.rowUserView(row, 'edit')
          },
          btnOptType: 'text',
        }, {
          label: "黑名单",
          show: (row) => {
            return !this.APITYPE
          },
          handle: (row) => {
            this.openBlackList(row, 'personnel')
          },
          btnOptType: 'text',
        },
        {
          label: "入场",
          show: (row) => {
            return !this.APITYPE  && (row.isUsed == 2 || row.isUsed == 1 || row.isUsed == 3)
          },
          handle: (row) => {
            this.rowUse(row)
          },
          btnOptType: 'text',
        },
        //   {
        //   label: "入场培训",
        //     show:(row)=>{
        //       return this.APITYPE == 'user'&& row.id && row.educateId != -1 && row.educateId != '' && !row.trained
        //     },
        //     handle:(row)=>{
        //         this.addPx([row])
        //     },
        //   btnOptType: this.APITYPE == 'user' ? "primary" :'text',
        // },
        {
          label: "入场",
          show: (row) => {
            return this.APITYPE == 'user' && row.id && row.isUsed == '2'
          },
          handle: (row) => {
            this.$confirm('<span style="color:red">请完成当前人员的入场培训；请上传当前人员的资质证书；请上传身体健康确认书；请上传三级教育卡</span>', '第三方入场提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            }).then(() => {
              this.saveData({
                ...row,
                isUsed: '0'
              })
            }).catch(() => {
            });
          },
          emit: "head-save",
          btnOptType: this.APITYPE == 'user' ? "success" : 'text',
        }, {
          label: "离场",
          show: (row) => {
            return row.isUsed == '0'
          },
          handle: (row) => {
            this.$confirm('确定离场当前人员?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.saveData({
                ...row,
                isUsed: '1'
              })
            }).catch(() => {
            });

          },
          btnOptType: this.APITYPE == 'user' ? "danger" : 'text',
        }, {
          label: "拒绝",
          show: (row) => {
            return row.id && row.isUsed == '2'
          },
          handle: (row) => {
            this.showDialog1(row)

          },
          btnOptType: this.APITYPE == 'user' ? "danger" : 'text',
        },
        {
          label: "允许入场",
          show: (row) => {
            return row.isUsed == '3' && this.APITYPE == 'user'
          },
          handle: (row) => {
            this.saveData({
              ...row,
              isUsed: '2'
            })
          },
          btnOptType: this.APITYPE == 'user' ? "success" : 'text',
        }, {
          label: "重新入场",
          show: (row) => {
            return row.isUsed == '1' && this.APITYPE == 'user'
          },
          handle: (row) => {
            this.$confirm('当前人员已离场，是否重新入场?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.saveData({
                ...row,
                isUsed: '2'
              })
            }).catch(() => {
            });
          },
          btnOptType: this.APITYPE == 'user' ? "success" : 'text',
        },
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          show: (row) => {
            return this.APITYPE == 'user'
          },
          handle: () => {
            this.closeDialog()
          },
          btnOptType: "default",
        },
      );
      return result;
    },
    userOption() {
      return {
        menuBtn: false,
        labelWidth: 140,
        column: [
          {
            label: '三级教育卡',
            prop: 'business',
            type: 'upload',
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            dataType: 'object',
            listType: 'picture-card',
            accept: 'image/*',
            fileSize: 5000,
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
          }, 
          {
            label: "证书编号",
            prop: "cerCode-business",
          },
          {
            label: "有效日期",
            prop: "limitDate-business",
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            type: "date",
          },
          {
            label: '身体健康确认书',
            prop: 'permit',
            type: 'upload',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            fileSize: 5000,
            span: 24,
            propsHttp: {
              res: 'data'
            },
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
          },
          {
            label: "证书编号",
            prop: "cerCode-permit",
          },
          {
            label: "有效日期",
            prop: "limitDate-permit",
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            type: "date",
          },
        ]
      }
    },
  },
  data() {
    return {
      showPage: false,
      isUsedDict: {
        '0': 'success',
        '1': 'info',
        '2': '',
        '3': 'danger',
      },
      saveBack: false,
      dialogLoading: false,
      search: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      fileIds: {},
      fileFormItem: {},
      fileForm: {
        business: [],
        permit: [],
        insure: [],
      },
      selectOption: {
        menuBtn: false,
        labelWidth: 140,
        column: [],
      },

      formOption: {
        menuBtn: false,
        labelWidth: 110,
        column: [
          {
            label: '营业执照',
            prop: 'business',
            type: 'upload',
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            dataType: 'object',
            listType: 'picture-card',
            accept: 'image/*',
            fileSize: 5000,
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
          },
          {
            label: "统一社会信用代码",
            prop: "cerCode",
            blur: (val) => {
              this.handleCode(val)
            },
          },
          
           {
            label: '施工许可证',
            prop: 'permit',
            type: 'upload',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            fileSize: 5000,
            span: 24,
            propsHttp: {
              res: 'data'
            },
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
          },
          {
            label: "证件编号",
            prop: "cerCode1",
            blur: (val) => {
              this.handleCode(val)
            },
          },
          {
            label: "有效日期",
            prop: "endDay1",
            change: (val) => {
              this.handleDate(val)
            },
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            type: "date",
          },
           {
            label: '团体保险',
            prop: 'insure',
            type: 'upload',
            listType: 'picture-card',
            accept: 'image/png, image/jpeg',
            fileSize: 5000,
            span: 24,
            propsHttp: {
              res: 'data'
            },
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
          },
          {
            label: "保险到期日期",
            prop: "endDay",
            change: (val) => {
              this.handleDate(val)
            },
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            type: "date",
          },
          {
            label: "保额",
            prop: "insuranceLimit",
            blur: (val) => {
              this.handleInsuranceLimit(val)
            },
          }
        ]
      },
      activeName: 'qyzz',
      optionDict: {
        searchBtnText: ' ',
        searchBtnIcon: 'el-icon-search',
        emptyBtnText: ' ',

        emptyBtnIcon: 'reset-refresh icon-refresh',
        height: '300',
        searchShow: true,
        card: false,
        border: true,
        viewBtn: false,
        addBtn: false,
        removeBtn: false,
        refreshBtn: false,
        searchBtn: true,
        columnBtn: false,
        searchShowBtn: true,
        index: true,
        indexLabel: '序号',
        searchSpan: 6,
        searchMenuSpan: 3,
        delBtn: false,
        editBtn: false,
        filterBtn: false,
        addRowBtn: false,
        header: false,
        menu: false,
      },
      option: {
        ryqd: {
          API: () => prjthirduserPage,
          query: () => {
            return {prjId: this.form.prjId, deptId: this.form.id}
          },
          column: [
            {label: '二维码', align: "center", prop: 'baseCode', slot: true},
            {
              label: '姓名',
              searchSpan: 4,
              align: "center",
              search: true,
              searchPlaceholder: '请输入人员姓名',
              overHidden: true,
              prop: 'userName'
            },
            {
              label: "",
              startProp: "startAge",
              prop: "startAge",
              endProp: "endAge",
              span: 4,
              search: true,
              hide: true,
            },
            {
              label: '年龄', align: "center", overHidden: true, prop: 'birthday', html: true,
              formatter: (row) => {
                let style = this.itemObject(row)
                return `<span style="color:${style.color}">${this.getAge(row.birthday)}</span>`
              }
            },
            {label: '联系电话', width: 150, align: "center", overHidden: true, prop: 'phone'},
            {label: '身份证', width: 240, align: "center", overHidden: true, prop: 'idCoding'},
            {label: '籍贯', align: "center", overHidden: true, prop: 'domicile'},
            {
              label: '岗位',
              searchSpan: 4,
              align: "center",
              search: true,
              searchPlaceholder: '请输入岗位',
              overHidden: true,
              prop: 'post'
            },
            {
              label: '黑名单状态', align: "center", minWidth: 100, overHidden: true,
              formatter: (row, value, label) => {
                return row.inBlacklist ? "是" : "否";
              },
              prop: 'inBlacklist'
            },
            {
              label: '通过入场培训', align: "center", minWidth: 120, overHidden: true, dicData: [
                {value: 1, label: '是'},
                {value: 0, label: '否'},
              ], prop: 'isExam'
            },
            {
              label: '特种人员',
              searchSpan: 4,
              type: 'select',
              search: true,
              searchPlaceholder: '请选择特种人员',
              align: "center",
              minWidth: 110,
              dicData: [
                {value: 1, label: '是'},
                {value: 0, label: '否'},
              ],
              overHidden: true,
              prop: 'isSpecial'
            },
            {label: '入场状态', align: "center", overHidden: true, prop: 'name'},
            {label: '更新时间', width: 150, align: "center", overHidden: true, prop: 'updateTime'},
          ]
        },
        pxjl: {
          API: () => API.personnelTrainRecord,
          query: () => {
            return {userId: this.form.id, currentPage: this.page.currentPage, pageSize: this.page.pageSize}
          },
          column: [
            {
              label: "培训类型",
              prop: "trainingType",
              align: "left",
              searchSpan: 4,
              searchLabelWidth: 80,
              search: true,
              overHidden: true,
            }, {
              label: "名称",
              prop: "courseName",
              align: "left",
              searchSpan: 4,
              searchLabelWidth: 60,
              search: true,
              overHidden: true,
            },
            {
              label: "课程",
              prop: "courseCover",
              slot: true,
              width: 120,
            },
            {
              label: "课时",
              prop: "totalHours",
              align: "center",
              width: 80,
              overHidden: true,
            },
            {
              label: "状态",
              searchSpan: 4,
              searchLabelWidth: 60,
              prop: "status",
              align: "center",
              search: true,
              type: 'select',
              dicUrl:
                "/api/sinoma-system/dict-biz/dictionary?code=education_status",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              width: 80,
              dataType: "number",
              overHidden: true,
            },
            {
              label: "标签",
              prop: "label",
              align: "center",
              overHidden: true,
              width: 140,
            },
            {
              label: "达标课时",
              prop: "standardsHours",
              align: "center",
              width: 90,
              overHidden: true,
            },
            {
              label: "完成必修课时",
              prop: "accomplishAccomplish",
              align: "right",
              width: 130,
              overHidden: true,
            },
            {
              label: "是否完成",
              prop: "isAccomplish",
              align: "center",
              dicUrl:
                "/api/sinoma-system/dict-biz/dictionary?code=education_is_accomplish",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              width: 90,
              dataType: "number",
              overHidden: true,
            },
            {
              label: "培训时间",
              prop: "trainingTimeRange",
              align: "center",
              width: 240,
              search: true,
              type: "daterange",
              format: 'yyyy-MM-dd',
              valueFormat: 'yyyy-MM-dd',
              change: (row) => {
              },
            },
          ],
        },
        xmjl: {
          API: () => {
            return this.APITYPE != 'user' ? prjbasicinfoPageByCompanyId : prjbasicinfoPageByUserId
          },
          query: () => {
            return this.APITYPE != 'user' ? {companyCode: this.form.companyCode} : {userId: this.form.userId}
          },
          column: [
            {
              label: "搜索",
              prop: "condition",
              align: "center",
              search: true,
              searchSpan: 4,
              hide: true,
              searchPlaceholder: '请输入项目名称或编号',
            },
            {
              label: "项目编码",
              prop: "prjCode",
              align: "center",
              searchPlaceholder: '请输入项目编码',
              overHidden: true,
              minWidth: 200,
            },
            {
              label: "项目名称",
              prop: "prjName",
              align: "center",
              overHidden: true,
              minWidth: 200,
            },
            {
              label: "境内外",
              prop: "isDomestic",
              dataType: "number",
              align: "center",
              minWidth: 80,
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=domestic_overseas`,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              overHidden: true,
            },
            {
              label: "项目状态",
              prop: "prjState",
              align: "center",
              dataType: "number",
              minWidth: 80,
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              overHidden: true,
            },
            {
              label: "进出场",
              prop: "changeState",
              align: "center",
              dataType: "number",
              minWidth: 80,
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_enter_leave`,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              overHidden: true,
            },
            {
              label: "进出场时间",
              prop: "changeDate",
              align: "center",
              minWidth: 150,
              overHidden: true,
            },

          ],
        },
        hmdjl: {
          query: () => {
            return {companyCode: this.form.companyCode}
          },
          API: () => prjblacklistorglist,
          column: [
            {
              label: "项目编号",
              prop: "prjCode",
              align: "center",
              searchSpan: 4,
              search: true,
              searchPlaceholder: '请输入项目编号',
              overHidden: true,
            },
            {
              label: "项目名称",
              prop: "prjName",
              align: "center",
              overHidden: true,
            },
            {
              label: "变更状态",
              prop: "blState",
              align: "center",
              dataType: "number",
              // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
              dicData: [
                {dictValue: "加入黑名单", dictKey: 1}, // FINISHED
                {dictValue: "解除黑名单", dictKey: 0}, // PREPARE
              ],
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              overHidden: true,
            },
            {
              label: "变更时间",
              prop: "updateTime",
              align: "center",
              overHidden: true,
            },
          ]
        },
        jcjl: {
          API: () => decideLinePage,
          query: () => {
            return this.APITYPE != 'user' ? {
              current: this.page.currentPage,
              size: this.page.pageSize,
              orgId: this.form.id
            } : {current: this.page.currentPage, size: this.page.pageSize, userId: this.form.userId}
          },
          column: [
            {
              label: "文书名称",
              searchSpan: 4,
              prop: "decideDocument",
              align: "center",
              search: true, searchPlaceholder: '请输入文书名称',
              overHidden: true,
              // slot: true,
            },
            {
              label: "奖惩单位",
              prop: "orgName",
              align: "center",
              overHidden: true,
            },
            {
              label: "接收对象",
              prop: "userName",
              align: "center",
              overHidden: true,
            },
            {
              label: "奖惩类型",
              prop: "decideType",
              align: "center",
              dataType: "number",
              overHidden: true,
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=decide_type",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
            },
            {
              label: "奖惩时间",
              prop: "decideDate",
              align: "center",
              overHidden: true,
            },
          ],
        },
        jccjl: {
          API: () => getList,
          query: () => {
            return {current: this.page.currentPage, size: this.page.pageSize, partnerUserId: this.form.userId}
          },
          column: [
            {
              label: "人员",
              prop: "personnelName",
              formatter: (row) => {
                return row.wholeState == 1 ? row.insideUserName : row.partnerUserName
              },
              align: "center",
              minWidth: 120,
              overHidden: true,
            },
            {
              label: "组织",
              prop: "organizationName",
              align: "left",
              formatter: (row) => {
                return row.wholeState == 1 ? row.insideOrgName : row.partnerOrgName
              },
              minWidth: 200,
              overHidden: true,
            },
            {
              label: "类型",
              prop: "wholeState",
              align: "center",
              dataType: "number",
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=whole_state`,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              minWidth: 115,
              overHidden: true,
            },
            {
              label: "进出场",
              prop: "changeState",
              align: "center",
              dataType: "number",
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=eae_prj_state`,
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              minWidth: 80,
              overHidden: true,
            },
            {
              label: "进出场时间",
              prop: "changeDate",
              align: "center",
              minWidth: 170,
              overHidden: true,
            },
            {
              label: "变更人",
              prop: "createUserName",
              align: "center",
              minWidth: 120,
              overHidden: true,
            },
            {
              label: "变更人组织",
              prop: "createDeptName",
              align: "left",
              minWidth: 200,
              overHidden: true,
            },
          ],
        },
      },
    }
  },
  methods: {
    rowRouter(row) {

    },
    addPx(userList) {//新增培训
      if (userList.length == 0) return this.$message.error('请选择需要培训的人员')
      let stPostId = userList.map((item) => item.stPostId)
      let everyResult = stPostId.every(item => item == stPostId[0])
      if (!everyResult) return this.$message.error('请选择同一批工种培训')
      this.$router.push({
        path: "/business/safetyenment/train/trainingTaskListPageAdd",
      });
      sessionStorage.setItem("courseId", userList[0].educateId);
      sessionStorage.setItem("userList", JSON.stringify(userList.map(item => {
        return {
          ...item,
          deptName: item.companyName,
          deptId: item.companyId,
          storagelode: "trainingTask"
        }
      })));
      sessionStorage.setItem("taskType", "other");
    },
    itemObject(item) {
      let age = this.getAge(item.birthday)
      let overDue = false;
      if (item.insuranceEndDate) {
        let newDate = new Date(
          dateFormat(new Date(), "yyyy-MM-dd")
        ).getTime();
        let str = new Date(item.insuranceEndDate).getTime();
        overDue = str < newDate;
      }
      if (
        (item.sex == 1 && age >= 50) ||
        age >= 60 || age < 18 ||
        overDue
      ) {
        return {color: "red"};
      } else {
        return {color: "black"};
      }
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage
      this.getTable()
    },
    searchReset() {
      this.search = {};
      this.page.currentPage = 1;
      this.getTable()
    },
    searchChange(params, done) {
      this.search = params;
      this.page.currentPage = 1;
      this.getTable()
      done()
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getTable()
    },
    getTable() {
      this.tableData = [];
      this.page.total = 0;
      let API = this.option[this.activeName].API()
      let query = this.option[this.activeName].query()
      API({...query, ...this.search}).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data.records || res.data.data
          let pageObj = res.data.data
          this.page.total = pageObj.total || 0;
          this.tableData = data;
        }
      })
    },
    uploadDelete(file, column) {
      this.fileForm[column.prop].splice(file.uid, 1);
    },
    uploadAfter(res, done, loading, column) {
      if (!this.fileForm[column.prop]) {
        this.$set(this.fileForm, column.prop, [])
      }
      this.fileForm[column.prop].push({
        ...res,
        uid: new Date().getTime(),
        thirdCompanyId: this.form.id,
        companyCode: this.form.companyCode,
        prjId: this.form.prjId,
        endDay: column.prop=='permit' ? this.fileForm.endDay1 : this.fileForm.endDay,
        cerCode: column.prop=='permit' ? this.fileForm.cerCode1 : this.fileForm.cerCode,
        insuranceLimit: this.fileForm.insuranceLimit,
        certificateOrder: column.prop == 'business' ? 1 : column.prop == 'permit' ? 2 : 3,
        certificateType: column.prop
      })
      console.log(this.fileForm,column.prop)
      this.$forceUpdate()
      loading()
    },
    handleDate({value,column}) {
      if(column.prop=='endDay1'){
        this.fileForm.permit.forEach((item => {
          item.endDay = value
        }))
      }else if(column.prop=='endDay'){
        this.fileForm.insure.forEach((item => {
          item.endDay = value
        }))
      }
    },
    handleCode({value,column}){
      if(column.prop=='cerCode1'){
        this.fileForm.permit.forEach((item => {
          item.cerCode = value
        }))
      }else if(column.prop=='cerCode'){
        this.fileForm.business.forEach((item => {
          item.cerCode = value
        }))
      }
    },
    handleInsuranceLimit({value,column}){
      this.fileForm.insure.forEach((item => {
        item.insuranceLimit = value
      }))
    },
    

    headSave() {
      let fileForm = {
        ...this.fileForm
      }
      console.log('fileForm',fileForm)
      delete fileForm.endDay
      delete fileForm.endDay1
      delete fileForm.cerCode
      delete fileForm.cerCode1
      delete fileForm.insuranceLimit
      let fileData = Object.values(fileForm).flat().filter(item => item)
      console.log('fileData',fileData)
      let objVal = fileData.filter(item => item.certificateOrder == 1)
      let objVal1 = fileData.filter(item => item.certificateOrder == 2)
      let objVal2 = fileData.filter(item => item.certificateOrder == 3)
      let newObj = [
        {
          ...objVal[0],
          id: this.fileFormItem.business,
          certificateDetail: JSON.stringify(objVal)
        }, {
          ...objVal1[0],
          id: this.fileFormItem.permit,
          certificateDetail: JSON.stringify(objVal1)
        }, {
          ...objVal2[0],
          id: this.fileFormItem.insure,
          certificateDetail: JSON.stringify(objVal2)
        },
      ]
 
      // fileSubmit(newObj).then(res => {
      //   if (res.data.code == 200) {
      //     this.$message.success(res.data.msg)
      //     this.getFile()
      //   }
      // })
    },

    getFile(id) {
      getFileList({
        thirdCompanyId: this.form.deptId,
        companyCode: this.form.companyCode
      }).then(res => {
        res.data.data.forEach(item => {
          let certificateOrder = item.certificateOrder == 1 ? 'business' : item.certificateOrder == 2 ? 'permit' : 'insure';
          let certificateDetail = item.certificateDetail ? JSON.parse(item.certificateDetail) : []
          this.fileFormItem[certificateOrder] = item.id
          this.fileForm[certificateOrder] = certificateDetail

          if (item.certificateType == 'insure') {
            this.fileForm.endDay = item.endDay
            this.fileForm.insuranceLimit = item.insuranceLimit
          }else if(item.certificateType=='permit'){

            this.fileForm.endDay1 = item.endDay
            this.fileForm.cerCode1 = item.cerCode
          }else if(item.certificateType=='business'){

            this.fileForm.cerCode = item.cerCode
          }

        })
      })
    },
    uploadSized(msg, column) {
      this.$message.error(`${column.label}${msg}`)
    },
    showDialog1(row) {
      this.$DialogForm.show({
        title: '拒绝入场',
        width: '30%',
        menuPosition: 'right',
        option: {
          submitText: '确定',
          span: 24,
          column: [
            {
              span: 24,
              type: 'select',
              label: "拒绝原因",
              prop: "rejectReason",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_user_reject_reason",
              rules: [{
                required: true,
                message: "请选择拒绝原因",
                trigger: "change"
              }],
            },
            {
              label: "备注说明",
              type: 'textarea',
              minRows: 3,
              maxRows: 5,
              maxlength: 225,
              showWordLimit: true,
              prop: "refuseRemark"
            }
          ]
        },
        callback: (res) => {
          this.saveData({
            ...row,
            isUsed: '3',
            ...res.data,
          })
          res.close()
        }
      })
    },
    saveData(data) {
      this.dialogLoading = true
      let  newData={
        thirdFlg: '1',
        ...data,
        roleIds: typeof data.roleIds != 'string' ?data.roleIds?.join(','):data.roleIds,
        educateId: data.educateId1,
        recentPhoto: typeof  data.recentPhoto != 'string' ? JSON.stringify(data.recentPhoto):data.recentPhoto ,
        idFiles:typeof data.idCodingImg != 'string' ? JSON.stringify(data.idCodingImg) : data.idCodingImg,
        idFileBack:typeof  data.idCodingImg1 != 'string'  ? JSON.stringify(data.idCodingImg1) : data.idCodingImg1
      }
      newData.account=newData.thirdFlg == '1'  ? newData.phone :newData.account;
      prjthirduserSubmit(newData).then(res => {
        this.treeReadOnlyFlag = true;
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        } else {
          this.$message.success(res.data.msg);
        }
        if (this.APITYPE == 'user') {
          this.$emit('callback')
        }
        if(this.saveBack){
          this.closeDialog()
        }
        this.form = {
          id: res.data.data,
        }
        this.callBack && this.callBack()
        this.getPrjstructureuserList && this.getPrjstructureuserList()
        this.showDialog = false;
        this.dialogLoading = false
      }).catch(err => {
        this.dialogLoading = false
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    formatter: (row) => {
      let baseCode = "data:image/png;base64," + row.baseCode.codeUrl || ''
      return baseCode
    },
    rowUserView(row, type) {
      this.$router.push({
        path: '/safe/itemList/index/addThirduser',
        query: {type: type || "view", prjId: this.parentId, id: row.id}
      })
    },
    closeDialog() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
}
